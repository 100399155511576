import plannignApi from "@/api/PlanningApi"
import Swal from "sweetalert2"
import { i18n } from '@/main.js'
import { responseErrorText } from "@/helpers/Parser";

export const saveHidden = async (context,hidden) => {
    const vada = context.rootState.vada
    const path = `/v1/timeHidden?vada=${vada}&number=${hidden}`
    let response = plannignApi.post(path).then(getResponse => { 
    return getResponse.data.message
    })
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while saving time hidden`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    })
    return response

}

export const saveRange = async (context,range) => {
    const vada = context.rootState.vada
    const path = `/v1/timeRange?vada=${vada}&number=${range}`
    let response = plannignApi.post(path).then(getResponse => {
    return getResponse.data.message
    })
      .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while saving time range`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    })

   return response

}