import cmrApi from "@/api/CmrApi"
import Swal from "sweetalert2"
import { i18n } from '@/main.js'
import { responseErrorText } from "@/helpers/Parser";

export const getCmrByNumber = (state) => (number) => {
    const path = `/v1/cmrByNumber?cmrNumber=${number}`
    const response = cmrApi.get(path).then(getResponse => {
    state.cmr = getResponse.data
    state.isLoading = false
    return getResponse.data
})
.catch(err => {
    state.cmr = []
    Swal.fire({
        icon: "error",
        title: `No se ha encontrado ningún CMR con este número`,
        text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
        confirmButtonColor: "#007DBC",
        cancelButtonColor: "#6e7d88"
    })
  
})
return response
}

export const getCmrByShipmentNumber = (state) => (shipmentNumber) => {
    const path = `/v1/cmrByShipmentNumber?shipmentNumber=${shipmentNumber}`
    const response = cmrApi.get(path).then(getResponse => {
        state.cmr = getResponse.data
        state.isLoading = false
        return getResponse.data
    })
    .catch(err => {
        state.cmr = []
        Swal.fire({
            icon: "error",
            title: `No se ha encontrado ningún CMR para este número de expedición.`,
            text: `Asegúrate de no introducir el prefijo de la expe (82) si no es necesario. Recuerda que el número de expe tiene que ser idéntico al número de expe que se usó para cerrar el CMR: ` + err ,
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    
    })
    return response
   
}

export const cmrsByDestiny = (state,getters,rootState) => async (destiny,{page,size,order}) => {
   
    const vada = rootState.vada;
    Swal.fire({
          title: i18n.global.t('waitPlease'),
        allowOutsideClick: false,
        confirmButtonColor: "#3643BA",
        cancelButtonColor: "#6e7d88"
      });
    Swal.showLoading(); 

    try {
        const path = `/v1/cmrsByDestiny?vada=${vada}&destiny=${destiny}&page=${page}&size=${size}&sort=${order}`
        const response = await cmrApi.get(path)
        
        state.cmrs = [...response.data.content]
        Swal.close()
        return response.data

    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    }
    
}

export const cmrsByCarrier = (state,getters,rootState) => async (carrier,{page,size,order}) => {
 
    const vada = rootState.vada;
    Swal.fire({
          title: i18n.global.t('waitPlease'),
        allowOutsideClick: false,
        confirmButtonColor: "#3643BA",
        cancelButtonColor: "#6e7d88"
      });
    Swal.showLoading(); 

    try {
        const path = `/v1/cmrsByCarrier?vada=${vada}&carrier=${carrier}&page=${page}&size=${size}&sort=${order}`
        const response = await cmrApi.get(path)      
        
        state.cmrs = [...response.data.content]
        Swal.close()
        return response.data
    
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    }    
    
}

export const getReturns = (state,getters,rootState) => async () => {
    try {
        const vada = rootState.vada
        const path = `/v1/return?vada=${vada}`
        const response = await cmrApi.get(path)
        if (response.data) {
            state.returns = [...response.data]
            state.isLoading = false
        } else {
            state.returns = []
            state.isLoading = false
        }
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error while getting returns`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    }
}

export const getDriver = () => async (driverNif) => {
    try {
        const path = `/v1/driver?driverNif=${driverNif}`
        const response = await cmrApi.get(path)

        if (!response.data) return
        return response.data[0]

    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error while getting driver`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    }
}

export const getProcesses = (state,getters,rootState) => async () => {
    try {
        const path = `/v1/processes`
        const response = await cmrApi.get(path)

        if (!response.data) return
        state.processes = [...response.data]
        state.isLoading = false
        state.vada = rootState.vada

    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error while getting processes`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    }
    
}

export const getCmrs = (state,getters,rootState) => async (initialDate, finalDate) => {
    try {
        const vada = rootState.vada
        const path = `/v1/cmrByDates?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}`
        const response = await cmrApi.get(path)

        if (!response.data) return
        state.cmrs = [...response.data]
        return [...response.data]

    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error while getting cmrs`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#3643BA",
            cancelButtonColor: "#6e7d88"
        })
    }
}



// GET SHIPMENT DATA
export const getShipmentsData = (state,getters,rootState) => async (params, {page,size,order}) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading();
    
    let vada = (params.country !== 'Mi centro') ? params.country : rootState.vada;

    let countryView = (params.country !== 'Mi centro');

    let path = `/v1/shipmentsData?initialDate=${params.initialDate}&finalDate=${params.finalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&sort=${order}&page=${page}&size=${size}&returns=${params.returns}&countryView=${countryView}&timeZone=${params.timeZone}`;
    if(params.destiny !== 'Todos los destinos') {
        path += `&destiny=${params.destiny}`;
    }
    if(params.carrier !== 'Todos los transportistas' ) {
        path += `&carrier=${params.carrier}`;
    }    
    
    const response = await cmrApi.get(path);
    state.shipmentsData = response.data.content;
    Swal.close();
    return response.data;
  };

export const getShipmentsDataNotPageable = (state,getters,rootState) => async (initialDate, finalDate,destiny,country,carrier) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading()
  
    let vada = (country !== 'Mi centro') ? country : rootState.vada;

    let countryView = (country !== 'Mi centro');

    let path = `/v1/shipmentsDataNotPageable?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}&countryView=${countryView}`;
    if(destiny !== 'Todos los destinos') {
        path += `&destiny=${destiny}`;
    }
    if(carrier !== 'Todos los transportistas' ) {
        path += `&carrier=${carrier}`;
    }
   
    const response = await cmrApi.get(path);
    state.shipmentsDataNotPaginated = response.data;
    Swal.close();
    return response.data;
}

export const getShipmentsExcel = (state,getters,rootState) => async (params)=> {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading();

    let vada = (params.country !== 'Mi centro') ? params.country : rootState.vada;

    let countryView = (params.country !== 'Mi centro');

    let formatedInitialDate = params.initialDate+" 00:00:00"
    let formatedFinalDate = params.finalDate+" 23:59:59"
    let path = `/v1/shipmentsData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&returns=${params.returns}&countryView=${countryView}&timeZone=${params.timeZone}`
    if(params.destiny !== 'Todos los destinos') {
        path += `&destiny=${params.destiny}`;
    }
    if(params.carrier !== 'Todos los transportistas' ) {
        path += `&carrier=${params.carrier}`;
    }   

    const response = await cmrApi.get(path);
    state.cmrs = response.data.content;
    Swal.close();
    return response.data;
}

export const getCmrCountGroupedByDestiny = (state,getters,rootState) => async (fromDate, toDate, returns, country, destiny, carrier) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading();

    // Get URL path
    let path = `/v1/cmrs/`;
    path += (country !== 'Mi centro') ? `country/${country}` : `vada/${rootState.vada}`;
    path += `/count?groupBy=destiny&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}`;
    if(destiny !== 'Todos los destinos') {
        path += `&destiny=${destiny}`;
    }
    if(carrier !== 'Todos los transportistas' ) {
        path += `&carrier=${carrier}`;
    }
  
    const response = await cmrApi.get(path);
    state.cmrCountDestiny = response.data;
    Swal.close();
    return response.data;
}

export const getCmrCountGroupedByCarrier = (state,getters,rootState) => async (fromDate, toDate, returns, country, destiny, carrier) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading();

    // Get URL path
    let path = `/v1/cmrs/`;
    path += (country !== 'Mi centro') ? `country/${country}` : `vada/${rootState.vada}`;
    path += `/count?groupBy=carrier&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}`;
    if(destiny !== 'Todos los destinos') {
        path += `&destiny=${destiny}`;
    }
    if(carrier !== 'Todos los transportistas' ) {
        path += `&carrier=${carrier}`;
    }
    
    const response = await cmrApi.get(path);
    state.cmrCountCarrier = response.data;
    Swal.close();
    return response.data;
}

export const getCmrCountGroupedByTruckType = (state, getters, rootState) => async (fromDate, toDate, returns, country, destiny, carrier) => {
    Swal.fire({
      title: 'Espere por favor',
      allowOutsideClick: false,
    })
    Swal.showLoading();

    // Get URL path
    let path = `/v1/cmrs/`;
    path += (country !== 'Mi centro') ? `country/${country}` : `vada/${rootState.vada}`;
    path += `/count?groupBy=truckType&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}`;
    if(destiny !== 'Todos los destinos') {
        path += `&destiny=${destiny}`;
    }
    if(carrier !== 'Todos los transportistas' ) {
        path += `&carrier=${carrier}`;
    }
    
    const response = await cmrApi.get(path);
    state.cmrCountTruckType = response.data;
    Swal.close();
    return response.data;
}

export const getCmrCountGroupedByCenter = (state) => async (country, fromDate, toDate, returns) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading();

    if(country === 'Mi centro') {
      return;
    }

    const path = `/v1/cmrs/country/${country}/count?groupBy=center&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}`;

    const response = await cmrApi.get(path);
    state.cmrCountCenter = response.data;
    Swal.close();
    return response.data;
}

export const getReceptionPageable = (state,getters,rootState) => async (initialDate, finalDate, timeZone, {page,size,order}) => {
    let formatedInitialDate = initialDate+" 00:00:00"
    let formatedFinalDate = finalDate+" 23:59:59"
    const vada = rootState.vada
    const path = `/v1/receptionData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&page=${page}&size=${size}&sort=${order}&timeZone=${timeZone}`
    const response= await cmrApi.get(path)
    state.receptions = response.data.content
    return response.data
}

export const getReceptionXls = (state,getters,rootState) => async (initialDate, finalDate, timeZone) => {
    let formatedInitialDate = initialDate+" 00:00:00"
    let formatedFinalDate = finalDate+" 23:59:59"
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    })
    Swal.showLoading()
    const vada = rootState.vada
    const path = `/v1/receptionData/xls?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&timeZone=${timeZone}`
    const { data } = await cmrApi.get(path)
    .catch(e =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No se han encontrado datos para estas fechas.',
            confirmButtonColor: '#3643BA',
            cancelButtonColor: '#6e7d88',
        })
        console.error ("Error técnico de la descarga: " + e)
        return
    })
    Swal.close()
    return data;
}

export const getFileFromBucket = () => async (filePath) => {
    Swal.fire({
        title: i18n.global.t('waitPlease'),
        allowOutsideClick: false,
    })
    Swal.showLoading()
    try {
        const path = `/v1/download`
        const response = await cmrApi.get(path,{
            params: {
                fileName: filePath 
            },
            responseType: 'arraybuffer'})
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filePath; 
        link.click();
        window.URL.revokeObjectURL(url); 
        Swal.close()
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No file found in bucket',
            confirmButtonColor: '#3643BA',
            cancelButtonColor: '#6e7d88',
        })
        throw new Error('No se pudo descargar el archivo');
    }
};

const fetchShipmentData = (state, rootState, path) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
    });
    Swal.showLoading();

    return cmrApi.get(path)
        .then((response) => {
            state.shipmentsData = Array.isArray(response.data) ? response.data : [response.data];
            Swal.close();
            return response.data;
        })
        .catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: i18n.global.t('shipmentDoesNotExistInData'),
                confirmButtonColor: '#3643BA',
                cancelButtonColor: '#6e7d88',
            })
            throw error;
        });
};

export const getShipmentByVadaAndCmrNumber = (state, getters, rootState) => async (cmrNumber, timeZone) => {
    let vada = rootState.vada;
    const path = `/v1/shipment/cmrNumber?vada=${vada}&cmrNumber=${cmrNumber}&timeZone=${timeZone}`;
    return fetchShipmentData(state, rootState, path);
};

export const getShipmentByVadaAndExpedition = (state, getters, rootState) => async (shipmentNumber, timeZone) => {
    let vada = rootState.vada;
    const path = `/v1/shipment/shipmentNumber?vada=${vada}&expedition=${shipmentNumber}&timeZone=${timeZone}`;
    return fetchShipmentData(state, rootState, path);
};

