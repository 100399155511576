

export const messages = {
      en: {
            STATE_1:'Create CMR (Real)',
            STATE_2:'Load date (Expected)',
            STATE_3:'Close CMR (Real)',
            STATE_4:'Truck leaves center',
            STATE_5:'Truck arrives destination',
            STATE_6:'Download Date (Expected)',
            STATE_7:'Download completed',
            STATE_8:'Returns date',
            STATE_9:'Return completed',
            STATE_10:'Truck leaves destination',
            STATE_11:'Truck arrives to center with returns',
            STATE_12:'Reception date',
            STATE_13:'Reception completed',
            departure: 'Departure',
            collaborator: 'Collaborator',
            transport: 'Transport',
            planning: 'Planning',
            planification: 'Planification',
            accounts: 'Accounts',
            invoice: 'Invoice',
            ordertype: 'Order Type',
            trucktype: 'Truck Type',
            truck: 'Truck',
            destiny: 'Destiny',
            center: 'Center',
            carrier: 'Carrier',
            configuration: 'Settings',
            cmrlife: 'Cmr Life',
            sustain: 'Sustainability',
            raq: 'Raq',
            productivity: 'Productivity (min)',
            packaging: 'Packaging',
            optimization: 'Optimization',
            selectOptimization: 'Select Optimization',
            data: 'Data',
            manual: 'Manual CMR',
            search: 'Search',
            cmrnumber: 'CMR NUMBER',
            selectdestiny: 'SELECT DESTINY',
            selectcarrier: 'SELECT CARRIER',
            changeuser: 'Change',
            canceluser: 'Cancel',
            modaltitleuser: 'Edit profile',
            userlocale: 'LOCALE',
            userrole: 'ROLE',
            username: 'USERNAME',
            decarange: 'Range Time',
            decahidden: 'Hidden Time',
            thereIsNoInfo: 'There is no information',
            openMainMenu: 'Open main menu',
            superAdmin: 'Super admin',
            admin: 'Admin',
            driver: 'Driver',
            spanish: 'Spanish',
            english: 'English',
            french: 'French',
            portuguese: 'Portuguese',
            italian: 'Italian',
            notAuthentificanted: 'Not authentificated',
            name: 'Name',
            expedition: 'Expedition',
            boxes: 'Boxes',
            weight: 'Weight',
            volume: 'Volume',
            selectStateRaq: 'Select STATE raq',
            trailerInGoodCondition: 'trailer in good condition',
            packingList: 'Packing List',
            bonoTransportation: 'Bono transportation',
            emptyPallets: 'Empty pallets',
            dangerousMaterials: 'Dangerous Materials',
            confirm: 'Confirm',
            selectACenter: 'Select a Center',
            edit: 'Edit',
            save: 'Save',
            centerName: 'Center name',
            adress: 'Adress',
            city: 'City',
            country: 'Country',
            countryCode: 'Country code',
            destinyName: 'Destiny name',
            destinyNumber: 'Destiny number',
            emailDestiny: 'Email destiny',
            emailOrganizer: 'Email organizer',
            emailAccountant: ' Email accountant',
            team: 'Team',
            dock: 'Dock',
            transitTime: 'Transit time (days)',
            loadOrder: 'Load order',
            selectLoadOrder: 'Select a load order',
            kilometers: 'Kilometers',
            isANationalDestiny: 'Is a national destiny',
            selectADestiny: 'Select a destiny',
            selectATruckType: ' Select a truck type',
            share: 'Share',
            price: 'Price',
            selectADay: 'Select a day',
            selectAPlanning: 'Select a planning',
            selectAFuel: 'Select a fuel',
            download: 'Download',
            cancel: 'Cancel',
            process: 'Process',
            registration: 'Registration',
            fuel: 'Fuel',
            trailer: 'Trailer',
            time: 'Time',
            dayLoad: 'Day load',
            weekLoad: 'Week load',
            hourLoad: 'Hour load',
            selectHourLoad: 'Select a hour load',
            hourDownload: 'Hour download',
            comment: 'Comment',
            seals: 'Seals',
            return: 'Return',
            returns: 'Returns',
            create: 'Create',
            selectACarrier: 'Select a carrier',
            billingDestination: 'Billing destination',
            date: 'Date',
            arrivalHour: 'Arrival Hour',
            createPlanning: 'Create Planning',
            downloadPlanning: 'Download Planning',
            address: 'Address',
            fullAddress: 'Street, Number, City',
            number: 'Number',
            type: 'Type',
            loadingTiming:'Carrier arrival',
            loaderTiming:'Charger Productivity',
            endLoadingHour:'Load End Time (Real)',
            selectCenter: 'Select Center Type',
            centerType: 'Center Type',
            typeWarehouse:"Warehouse Type",
            monday: 'Monday',
            tuesday: 'Tuesday',	
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',	
            saturday: 'Saturday',	
            sunday: 'Sunday',
            section: 'Section',
            store:'Store',
            load:'Load',
            editPlanning: 'Edit Planning',
            selectDay: 'Select a day',
            loading: 'Loading ...',
            bye: 'See you soon !',
            noCmrsToClose: 'no cmr to close',
            welcome:'Welcome',
            warning:'Warning',
            downloadDate:'Download Date',
            billing:"Billing",
            weekDownload:"Download week",
            dayDownload:"Download day",
            copy:"Copy",
            charger:"Charger",
            manualbox: "Manual box",
            areyousure:"Are you sure?",
            deletedText:"Once deleted, you cannot recover it",
            deletedsuccess:"Removed Successfully",
            shipped:"Shipped",
            returned:"returned",
            writeHere:"Write Here",
            hello:"Hello",
            initialdate:"From",
            finaldate:"To",
            protocolLink: "Security Protocol",
            languagePreference: "Select Language",
            timeZone: "Time Zone",
            selectTimeZone: "Select Time Zone",
            language: "Language",
            timez: "Time Zone",
            planningsCreated:"Plannings correctly created",
            hours:"Hours",
            reception:"Receptions",
            receptionHours:"ESTIMATED RECEPTION TIME",
            receptionHoursReal: "REAL RECEPTION TIME",
            origin:"Origin",
            arrivals:"Arrivals",
            selectOrderType:"Select stock type",
            filter:"Sort",
            deletefilter:"Delete filter",
            as400Alert:"If you use AS400 to find a shipment, keep in mind to not use the shipment prefix",
            weightAlert:"Please, note that the wieght does not exceed what is allowed by law",
            expeAS400:"Shipment num (without prefix)",
            returnCollaborator:"Return Collaborator",
            returnDate: "Return Date",
            shipmentDateOnReturnMenu: "Shipment date",
            shipmenCollaboratorOnReturnMenu:"Shipment collaborator",
            alertDeleting:"You are going to delete all the plannings that are not assoiciated yet to a CMR. You are not going to be able to undo this action.",
            selectSector:"Select sector",
            selectDestinyType:"Select destiny type",
            destinyType: "Destiny type",
            chargerName: "Charger name",
            searchingShipmentAS400: "Searching shipment data in AS400",
            searchingRaqAS400: "Searching RAQ status in AS400",
            searchingShipmentNumberAS400:"Searching shipment number in AS400",
            waitPlease: "Wait please",
            errorRaq:"Not RAQ status found for this destination",
            errorRaqDesc: "Please verify if the storeNumber is correctly set in DCMR settings. If the problem persists, please fill out the RAQ manually.",
            raqQuestion: "Didn't find any result in RAQ for this destination. Is RAQ OK?",
            atention:"Atention",
            thenErrorRaq: "Then an error must occurred",
            thenErrorRaqDesc: "Please verify if the storeNumber is correctly set in DCMR settings and try again",
            selectPackaging:"Select a packaging",
            shipments: "Shipments",
            returnsDoneFromYourCenter: "Returns done to your center",
            returnsDoneFrom: "Returns done to ",
            shipmentsDoneFromYourCenter: "Shipments sent from your center",
            shipmentsDoneFrom: "Shipments sent from ",
            yourCenter: "your center",
            shipmentsDoneByCenter: "Shipments done by center",
            shipmentsDoneByDestiny: "Shipments done by destination",
            shipmentsDoneByCarrier: "Shipments done by carrier",
            shipmentsDoneByTruckType: "Shipments done by truck type",
            returnsDoneByCenter: "Returns done by center",
            returnsDoneByDestiny: "Returns done by destination",
            returnsDoneByCarrier: "Returns done by carrier",
            arrivalDate: "Arrival date",
            departureDate: "Departure date",
            driverPuntuality: "Driver puntuality",
            quantity: "quantity",
            fill: "Fill",
            dataValidated: "Data valid from week 29, 2024",
            recepInStore: "Recep. store (Real)",
            successInStore: "Cmr correctly registered",
            year:"Year",
            cmrInStoreMenu:"Recep. CMR Store",
            driverPuntualityInStore: "Driver puntuality Store",
            myCenter: "My center",
            allDestinies: "All destinations",
            allCarriers: "All carriers",
            shipmentClosedCorrectly:" Shipment closed correctly",
            shipmentNotFound:"The AS400 expedition number could not be retrieved.",
            shipmentWarning: "Please, remember to check if the shipment number retrieved is correct",
            shipmentNotFoundAdvice: "Please, check if the destination number is set correctly in Config menu",
            delete:"Delete",
            deletePlannings: "Delete plannings",
            cif:"ID",
            nif:"Identification Document",
            searchingPackingList: "Searching packing list in AS400...",
            selectEnvironment:"Select environment...",
            uploadingFileInBucket: "Uploading file to the cloud...",
            as400Name:"Name in AS400",
            customizedName: "Customized name",
            tryAgainContactAdmin: "Please try again. If the problem persists, please contact and administrator",
            shipmentDoesNotExistInData: "This shipment does no exist in DCMR",
            selectEnvWarning:"Please, select a environment first",
            receptionNumber: "Reception number",
            missingPackagingType: "Missing packaging type",
            missingPackagingTypeText: "Do you want me to create the following package type and try to generate the CMR again? --> ",
            packagingCreated: "Packaging created correctly!",
            yes:"Yes",
            no:"No",
            errorCreatingPackaging:"Sorry an error has occurred while trying to create the packaging type. Please, do it manually in Configuration -> Packaging or try again later"


      },
      es: {
            STATE_1:'Creación del CMR (Real)',
            STATE_2:'Hora de carga (Estimado)',
            STATE_3:'Cierre del CMR (Real)',
            STATE_4:'El camión sale del centro',
            STATE_5:'Camión confirma en destino',
            STATE_6:'Comienza la descarga (Estimado)',
            STATE_7:'Finaliza la descarga',
            STATE_8:'Comienza la carga de devolución',
            STATE_9:'Cierre de la devolución',
            STATE_10:'Camión sale del destino',
            STATE_11:'Camión confirma llegada al centro para retorno',
            STATE_12:'Hora de descarga del retorno',
            STATE_13:'Cierre del retorno',
            departure: 'Ida',
            collaborator: 'Colaborador',
            transport: 'Transporte',
            planning: 'Planning',
            planification: 'Planificación',
            accounts: 'Usuarios',
            invoice: 'Orden',
            ordertype: 'Tipo de Stock',
            trucktype: 'Tipo de camión',
            truck: 'Camión',
            destiny: 'Destino',
            center: 'Centro',
            carrier: 'Transportista',
            configuration: 'Configuración',
            cmrlife: 'Vida del CMR',
            sustain: 'Sostenibilidad',
            raq: 'RAQ',
            productivity: 'Productividad (min)',
            packaging: 'Empaquetado',
            optimization: 'Optimización',
            selectOptimization: 'Selecciona Optimización',
            data: 'Datos',
            manual: 'CMR Manual',
            search: 'Buscar',
            cmrnumber: 'Nº de Cmr',
            selectdestiny: 'Selecciona Destino',
            selectcarrier: 'Selecciona Transportista',
            changeuser: 'Cambiar',
            canceluser: 'Cancelar',
            modaltitleuser: 'Editar perfil',
            userlocale: 'LOCAL',
            userrole: 'ROL',
            username: 'Nombre de usuario',
            decarange: 'Rango de Tiempo',
            decahidden: 'Tiempo de Ocultación',
            thereIsNoInfo: 'No hay información',
            openMainMenu: 'Abrir menú principal',
            superAdmin: 'Super admin',
            admin: 'Admin',
            driver: 'Chófer',
            spanish: 'Español',
            english: 'Inglés',
            french: 'Francés',
            portuguese: 'Portugués',
            italian: 'Italiano',
            notAuthentificanted: 'No autentificado',
            name: 'Nombre',
            expedition: 'Expedición',
            boxes: 'Bultos',
            weight: 'Peso',
            volume: 'Volumen',
            selectStateRaq: 'Seleccionar estado del RAQ',
            trailerInGoodCondition: 'Trailer en buenas condiciones',
            packingList: 'Packing List',
            bonoTransportation: 'Bono transporte',
            emptyPallets: 'Palets vacíos',
            dangerousMaterials: 'Materiales peligrosos',
            confirm: 'Confirmar',
            selectACenter: 'Seleccionar un centro',
            edit: 'Editar',
            save: 'Guardar',
            centerName: 'Nombre del centro',
            adress: 'Dirección',
            city: 'Ciudad',
            country: 'País',
            countryCode: 'Código de país',
            destinyName: 'Nombre del destino',
            destinyNumber: 'Número de destino',
            emailDestiny: 'Email de destino',
            emailOrganizer: 'Email del organizador',
            emailAccountant: 'Email de la cuenta',
            team: 'Equipo',
            dock: 'Muelle',
            transitTime: 'Tiempo de tránsito (días)',
            loadOrder: 'Orden de carga',
            selectLoadOrder: 'Seleccionar un orden de carga',
            kilometers: 'Kilómetros',
            isANationalDestiny: 'Es un destino nacional',
            selectADestiny: 'Seleccionar un destino',
            selectATruckType: 'Seleccionar un tipo de camión',
            share: 'Compartir',
            price: 'Precio',
            selectADay: 'Seleccionar un día',
            selectAPlanning: 'Seleccionar un planning',
            selectAFuel: 'Seleccionar un fuel',
            download: 'Descargar',
            cancel: 'Cancelar',
            process: 'Proceso',
            registration: 'Matrícula',
            fuel: 'Combustible',
            trailer: 'Trailer',
            time: 'Hora',
            dayLoad: 'Día de carga',
            weekLoad: 'Semana de carga',
            hourLoad: 'Hora de carga',
            selectHourLoad: 'Seleccionar una hora de carga',
            hourDownload: 'Hora de descarga',
            comment: 'Comentario',
            seals: 'Plomo',
            return: 'Retorno',
            returns: 'Devoluciones',
            create: 'Crear',
            selectACarrier: 'Seleccionar un transportista',
            billingDestination: 'Destino Facturación',
            date: 'Fecha',
            day: 'Dia',
            week: 'Semana',
            arrivalHour: 'Llegada chófer',
            createPlanning: 'Crear Planning',
            downloadPlanning: 'Descargar Planning',
            address: 'Dirección',
            fullAddress: 'Calle, Número, Ciudad',
            number: 'Número',
            type: 'Tipo',
            loadingTiming: 'Llegada Transportista',
            loaderTiming: 'Productividad Cargador',
            endLoadingHour: 'Finalización Carga (Real)',
            selectCenter:'Seleccione Tipo de Centro',
            centerType: 'Tipo de Centro',
            typeWarehouse: "Tipo Almacén",
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
            sunday: 'Domingo',
            section: 'Sección',
            store: 'Tienda',
            load: 'Carga',
            editPlanning: 'Editar Planning',
            selectDay: 'Selecciona un dia',
            loading: 'Cargando ...',
            bye: '¡ Nos vemos pronto !',
            noCmrsToClose: 'no hay ningún CMR pendiente de tratar',
            welcome: 'Bienvenido',
            warning:'Aviso',
            downloadDate:'Fecha de entrega',
            billing:"Facturación",
            weekDownload:"Semana de descarga",
            dayDownload:"Día de descarga",
            copy:"Copiar",
            charger:"Cargador",
            manualbox: "Bulto manual",
            areyousure:"¿Quieres confirmar tu acción?",
            deletedText:"Una vez borrado, ya no se puede recuperar",
            deletedsuccess:"Borrado satisfactoriamente",
            shipped:"Expedido",
            returned:"Devuelto",
            writeHere: "Escribe aquí",
            hello:"Hola",
            initialdate:"Desde",
            finaldate:"Hasta",
            protocolLink: "Protocolo de Seguridad",
            languagePreference: "Selecciona el idioma",
            timeZone: "Zona horaria",
            selectTimeZone: "Selecciona la zona horaria",
            language: "Idioma",
            timez: "Zona horaria",
            planningsCreated:"Plannings creados correctamente",
            hours:"Horas",
            reception:"Recepciones",
            receptionHours:"HORA DE RECEPCIÓN ESTIMADA",
            receptionHoursReal: "HORA DE RECEPCIÓN REAL",
            origin:"Origen",
            arrivals:"Llegadas",
            selectOrderType:"Seleccionar un tipo de stock",
            filter:"Filtrar",
            deletefilter:"Quitar filtro",
            as400Alert:"Si vas a usar AS400 para extraer la info de la expe, ten en cuenta de teclear bien su número y sin el prefijo",
            weightAlert:"Porfavor, revisa que el peso no supere lo establecido por ley",
            expeAS400:"Nº de expe AS400",
            returnCollaborator:"Colaborador Devo",
            returnDate: "Fecha Devo",
            shipmentDateOnReturnMenu: "Fecha expe",
            shipmenCollaboratorOnReturnMenu:"Colaborador expe",
            alertDeleting:"Vas a borrar todos los plannings seleccionados que no hayan sido asociados a un CMR. Esta acción es irreversible.",
            selectSector:"Selecciona sector",
            selectDestinyType:"Selecciona tipo de destino",
            destinyType: "Tipo de destino",
            chargerName: "Nombre cargador",
            searchingShipmentAS400: "Buscando datos de expedición en AS400",
            searchingRaqAS400: "Consultando estado del RAQ en AS400",
            searchingShipmentNumberAS400:"Buscando número de expedición en AS400",
            waitPlease: "Espere por favor",
            errorRaq:"No se ha podido obtener el estado del RAQ en AS400",
            errorRaqDesc: "Por favor, verifica que el número de tienda está correctamente configurado en los ajustes de DCMR. Si el problema persiste, rellena por favor el RAQ manualmente.",
            raqQuestion: "El RAQ para este destino está limpio en AS400. ¿Lo confirmas?",
            atention:"Atención",
            thenErrorRaq: "Entonces seguro ha habido un error",
            thenErrorRaqDesc: "Comprueba que el número de la tienda en cuestión está bien configurado en los ajustes de DCMR y vuelve a intentarlo. O tambien puedes poner el RAQ a mano en su respectivo apartado",
            selectPackaging:"Selecciona embalajes",
            shipments: "Expediciones",
            returnsDoneFromYourCenter: "Devoluciones realizadas a tu centro",
            returnsDoneFrom: "Devoluciones realizadas a ",
            shipmentsDoneFromYourCenter: "Expediciones realizadas desde tu centro",
            shipmentsDoneFrom: "Expediciones realizadas desde ",
            yourCenter: "tu centro",
            shipmentsDoneByCenter: "Expediciones realizadas por centro",
            shipmentsDoneByDestiny: "Expediciones realizadas por destino",
            shipmentsDoneByCarrier: "Expediciones realizadas por transportista",
            shipmentsDoneByTruckType: "Expediciones realizadas por tipo de camión",
            returnsDoneByCenter: "Devoluciones realizadas por centro",
            returnsDoneByDestiny: "Devoluciones realizadas por destino",
            returnsDoneByCarrier: "Devoluciones realizadas por transportista",
            arrivalDate: "Fecha de llegada",
            departureDate: "Fecha de salida",
            driverPuntuality: "Puntua. chofer almacén",
            quantity: "cantidad",
            fill: "Llenado",
            dataValidated: "Datos válidos a partir de semana 29, 2024",
            recepInStore: "Recep. Tienda (Real)",
            successInStore: "Cmr recepcionado correctamente",
            year:"Año",
            cmrInStoreMenu:"Recep. CMR Tienda",
            driverPuntualityInStore: "Puntua. chofer tienda",
            myCenter: "Mi centro",
            allDestinies: "Todos los destinos",
            allCarriers: "Todos los transportistas",
            shipmentClosedCorrectly:"Expedición cerrada correctamente",
            shipmentNotFound:"No se ha podido obtener el número de expe de AS400.",
            shipmentWarning: "Por favor, recuerda comprobar que el número de expe recuperado sea el correcto",
            shipmentNotFoundAdvice: "Por favor, revisa que el número de destino sea el correcto en el menú de Configuración",
            delete:"Borrar",
            deletePlannings: "Borrar plannings",
            cif:"CIF",
            nif:"NIF",
            searchingPackingList: "Buscando packing list en AS400...",
            selectEnvironment:"Selecciona entorno",
            uploadingFileInBucket: "Subiendo archivo a la nube...",
            tryAgainContactAdmin: "Por favor inténtalo de nuevo. Si el problema persiste, contacte con un administrador",
            as400Name:"Nombre en AS400",
            customizedName: "Nombre personalizado",
            shipmentDoesNotExistInData: "Esta expedición no existe en DCMR",
            selectEnvWarning:"Primero selecciona un entorno por favor",
            receptionNumber: "Nº Recepción",
            missingPackagingType: "Faltan tipos de empaquetado en DCMR",
            missingPackagingTypeText: "¿Deseas que cree por ti el siguiente tipo de empaquetado y vuelva a intentar generar el CMR? --> ",
            packagingCreated: "Empaquetado creado correctamente",
            yes:"Si",
            no:"No",
            errorCreatingPackaging:"Lo siento, ha habido un error al crear el empaquetado, por favor crealo de forma manual en Configuración-> Empaquetado o vuelve a intentarlo más tarde."
            
            
      },
      pt: {
            STATE_1: "Chegada ao Centro (Real)",
            STATE_2: "Carregar Camião (Previsão)",
            STATE_3: "Fechar CMR (Real)",
            STATE_4: "Saída do Centro",
            STATE_5: "Confirmado em Loja",
            STATE_6: "Inicio de descarga (Previsão)",
            STATE_7: "Fim de descarga",
            STATE_8: "Inicio Retorno",
            STATE_9: "Fim Retorno",
            STATE_10: "Saída do destino",
            STATE_11: "Chegada do retorno ao centro",
            STATE_12: "Inicia Retorna",
            STATE_13: "Fecha Retorno",
            departure: "Ida",
            collaborator: "Colaborador",
            transport: "Transporte",
            planning: "Planeamento",
            planification: "Planificação",
            accounts: "Usuário",
            invoice: "Pedido",
            ordertype: "Tipo de pedido",
            trucktype: "Tipo de camião",
            truck: "Camião",
            destiny: "Destino",
            center: "Centro",
            carrier: "Transportadora",
            configuration: "Configurações",
            cmrlife: "Vida do CMR",
            sustain: "Sustentabilidade",
            raq: "RAQ",
            productivity: "Produtividade (min)",
            packaging: "Packaging",
            optimization: "Otimização",
            selectOptimization: "Selecionar Otimização",
            data: "Dados",
            manual: "CMR Manual",
            search: "Procurar",
            cmrnumber: "Número do CMR",
            selectdestiny: "SELECIONAR DESTINO",
            selectcarrier: "SELECIONAR OPERADORA",
            changeuser: "Mudar",
            canceluser: "Cancelar",
            modaltitleuser: "Editar perfil",
            userlocale: "LOCALE",
            userrole: "FUNÇÃO/ROL",
            username: "NOME DO USUÁRIO",
            decarange: "Intervalo de tempo",
            decahidden: "Tempo oculto",
            thereIsNoInfo: "There is no information",
            openMainMenu: "Abrir menu principal",
            superAdmin: "Super admin",
            admin: "Admin",
            driver: "Condutor/camionista",
            spanish: "Espanhol",
            english: "Inglês",
            french: "Francês",
            portuguese: "Português",
            italian: "Italiano",
            notAuthentificanted: "Não autenticado",
            name: "Nome",
            expedition: "Expedição",
            boxes: "Caixas",
            weight: "Peso",
            volume: "Volume",
            selectStateRaq: "Seleccionar estado do RAQ",
            trailerInGoodCondition: "Trazer em boas condições",
            packingList: "Packing List",
            bonoTransportation: "Bono transporte",
            emptyPallets: "Pallets vazios",
            dangerousMaterials: "Materiais perigosos",
            confirm: "Confirmar",
            selectACenter: "Seleccionar um centro",
            edit: "Editar",
            save: "Guardar",
            centerName: "Nome do centro",
            adress: "Morada",
            city: "Cidade",
            country: "País",
            countryCode: 'Código do país',
            destinyName: "Nome do destino",
            destinyNumber: "Número do destino",
            emailDestiny: "Email do destino",
            emailOrganizer: "Email do organizador",
            emailAccountant: "Email contador",
            team: "Equipa",
            dock: "Cais",
            transitTime: "Tiempo de trânsito (dias)",
            loadOrder: "Ordem de carga",
            selectLoadOrder: "Selecionar um ordem de carga",
            kilometers: "Kilómetros",
            isANationalDestiny: "É um destino nacional",
            selectADestiny: "Selecionar um destino",
            selectATruckType: "Selecionar um tipo de camião",
            share: "Partilhar",
            selectADay: "Seleccionar um dia",
            selectAPlanning: "Selecionar um planning",
            selectAFuel: "Selecionar um combustível",
            download: "Descarga",
            cancel: "Cancelar",
            process: "Processo",
            registration: "Registro",
            fuel: "Combustível",
            trailer: "Reboque",
            time: "Hora",
            dayLoad: "Dia da carga",
            weekLoad: "Semana da carga",
            hourLoad: "Hora da carga",
            selectHourLoad: "Selecionar uma hora da carga",
            hourDownload: "Hora do descarga",
            comment: "Comentário",
            seals: "Selo",
            return: "Retornos",
            returns: "Retornos",
            create: "Criar",
            selectACarrier: "Selecionar uma transportadora",
            billingDestination: "Destino de cobrança",
            date: "Encontro",
            day: "Dia",
            week: "Semana",
            arrivalHour: "Hora de chegada",
            createPlanning: "Criar Planejamento",
            downloadPlanning: "Baixar Planejamento",
            address: "Morada",
            fullAddress: 'Rua, Número, Cidade',
            number: "Número",
            type: "Modelo",
            loadingTiming: "Chegada Transportador",
            loaderTiming: "Produtividade Carregador",
            endLoadingHour: "Hora término carga (Real)",
            selectCenter: "Selecione o tipo de centro",
            centerType: "Tipo de centro",
            typeWarehouse: "Tipo Armazém",
            monday: "Segunda-feira",
            tuesday: "Terça-feira",
            wednesday: "Quarta-feira",
            thursday: "Quinta-feira",
            friday: "Sexta-feira",
            saturday: "Sábado",
            sunday: "Domingo",
            section: "Seção",
            store: "Armazenar",
            load: "Carregar",
            editPlanning: "Editar programação",
            selectDay: "Selecione um dia",
            loading: "Carregando ...",
            bye: "Até logo !",
            noCmrsToClose: "não há CMR's pendentes a processar",
            welcome: "Bem-vindo",
            warning: "Aviso",
            downloadDate: "Data de descarga",
            billing: "Cobrança",
            weekDownload: "semana de descarga",
            dayDownload: "dia de descarga",
            copy: "copiar",
            charger: "carregador",
            manualbox: "Bulto Manual",
            areyousure: "tem a certeza?",
            deletedText: "uma vez eliminado, não poderá recuparar",
            deletedsuccess: "Removido com sucesso",
            shipped: "Expedição",
            returned: "Retorno",
            writeHere: "Escreva aqui",
            hello: "Ola",
            initialdate: "From",
            finaldate: "To",
            protocolLink: "Protocolo de Segurança",
            languagePreference: "Selecione o idioma",
            timeZone: "Fuso horário",
            selectTimeZone: "Selecione o fuso horário",
            language: "Idioma",
            timez: "Time Zone",
            planningsCreated: "Plannings criados con succeso",
            hours: "horas",
            reception: "Chegadas",
            receptionHours: "Hora estimada de recepção",
            receptionHoursReal: "hora real de recepção",
            origin: "Origem",
            arrivals: "Chegadas",
            selectOrderType: "Selecionar um tipo de stock",
            filter: "Filtrar",
            deletefilter: "Eliminar filtro",
            as400Alert: "Se usa o AS400 para encontrar a expedição, lembre-se de não usar o prefixo",
            weightAlert: "O peso não pode exceder o permitido por lei",
            expeAS400: "Número de Expedição (Sem prefixo)",
            returnCollaborator: "Colaborador do Retorno",
            returnDate: "Data do Retorno",
            shipmentDateOnReturnMenu: "Data de Expedição",
            shipmenCollaboratorOnReturnMenu: "Colaborador da expedição",
            alertDeleting: "vai elminar todos os plannings que ainda não estão associados a um CMR. Não poderá voltar atrás",
            selectSector: "selecionar setor",
            selectDestinyType:"Select destiny type",
            destinyType: "Destiny type",
            chargerName: "Nome carregador",
            searchingShipmentAS400: "Procurando data de expedição no AS400",
            searchingRaqAS400: "Verificando o status do RAQ no AS400",
            searchingShipmentNumberAS400 :"Procurando número de expedição no AS400",
            waitPlease: "Espere por favor",
            errorRaq:"Não foi possível obter o status RAQ no AS400",
            errorRaqDesc: "Verifique se o número da loja está configurado corretamente nas configurações do DCMR. Se o problema persistir, preencha o RAQ manualmente.",
            raqQuestion: "O RAQ para este destino está limpo no AS400. Você confirma isso?",
            atention:"Atention",
            thenErrorRaq: "Então deve ter havido um erro",
            thenErrorRaqDesc: "Verifique se o número da loja em questão está configurado corretamente nas configurações do DCMR e tente novamente.",
            selectPackaging:"Selecione a embalagem",
            shipments: "Expedições",
            returnsDoneFromYourCenter: "Devoluções feitas ao seu centro",
            returnsDoneFrom: "Devoluções feitas ao ",
            shipmentsDoneFromYourCenter: "Expedições feitas a partir do seu centro",
            shipmentsDoneFrom: "Expedições feitas a partir do ",
            yourCenter: "seu centro",
            shipmentsDoneByCenter: "Envios feitos por centro",
            shipmentsDoneByDestiny: "Envios feitos por destino",
            shipmentsDoneByCarrier: "Envios feitos por transportadora",
            shipmentsDoneByTruckType: "Envios feitos por tipo de camião",
            returnsDoneByCenter: "Devoluções realizadas por centro",
            returnsDoneByDestiny: "Devoluções realizadas por destino",
            returnsDoneByCarrier: "Devoluções realizadas por transportista",
            arrivalDate: "Data de chegada",
            departureDate: "Data de partida",
            driverPuntuality: "Pontualidade do motorista",
            quantity: "quantia",
            fill: "Preencher",
            dataValidated: "Dados válidos a partir da semana 29, 2024",
            recepInStore: "Recep. Loja (Real)",
            successInStore: "Cmr recepcionado correctamente",
            year:"Ano",
            cmrInStoreMenu:"CMR en Loja",
            driverPuntualityInStore: "Pontualidade motorista loja",
            myCenter: "Mi centro",
            allDestinies: "Todos os destinos",
            allCarriers: "Todos os transportistas",
            shipmentClosedCorrectly:"Expedição fechada corretamente",
            shipmentNotFound:"Não foi possível obter o número de expedição do AS400.",
            shipmentWarning: "Por favor, lembre-se de verificar se o número de expedição recuperado está correto",
            shipmentNotFoundAdvice: "Por favor, verifique se o número de destino está correto no menu de Configuração.",
            delete:"Supprimer",
            deletePlannings: "Supprimer plannings",
            cif:"CIF",
            nif:"NIF",
            searchingPackingList: "Procurando packing list no AS400...",
            selectEnvironment: "Selecione environment",
            uploadingFileInBucket: "Enviando arquivo para a nuvem...",
            tryAgainContactAdmin: "Por favor, tente novamente. Se o problema persistir, entre em contato com um administrador",
            as400Name:"Nome no AS400",
            customizedName: "Nom personalizado",
            shipmentDoesNotExistInData: "Este embarque não existe no DCMR",
            selectEnvWarning:"Por favor, selecione um ambiente primeiro",
            receptionNumber: "Nº Recepcion",
            missingPackagingType: "Tipo de embalagem ausente",
            missingPackagingTypeText: "Deseja que eu crie para você o seguinte tipo de embalagem e tente gerar o CMR novamente? --> ",
            packagingCreated: "Embalagem criado com sucesso",
            yes:"Sim",
            no:"Nao",
            errorCreatingPackaging:"Desculpe, ocorreu um erro ao criar a embalagem. Por favor, crie-a manualmente em Configuração -> Embalagem ou tente novamente mais tarde."

            
     },
     fr: {
      STATE_1: "Création CMR (Réel)",
      STATE_2: "Chargement (Prévisionnel)",
      STATE_3: "Fermeture CMR (Réel)",
      STATE_4: "Départ du centre",
      STATE_5: "Confirmé en magasin",
      STATE_6: "Déchargement (Prévisionnel)",
      STATE_7: "Fin du téléchargement",
      STATE_8: "Le retour commence",
      STATE_9: "Fermeture de retour",
      STATE_10: "Départ du destin",
      STATE_11: "Arrivée retour au centre",
      STATE_12: "Le retour commence",
      STATE_13: "Fermeture de retour",
      departure: "Sortie",
      collaborator: "Collaborateur",
      transport: "Transport",
      planning: "Planification",
      planification: "Planification",
      accounts: "Comptes",
      invoice: "Facture",
      ordertype: "Type de commande",
      trucktype: "Type de camion",
      truck: "Camion",
      destiny: "Destination",
      center: "Expediteur",
      carrier: "Transporteur",
      configuration: "Configuration",
      cmrlife: "La vie du CMR",
      sustain: "Durabilité",
      raq: "RAQ",
      productivity: "Temps de chargement (min)",
      packaging: "Conditionnement",
      optimization: "Optimisation",
      selectOptimization: "Sélectionnez Optimisation",
      data: "Données",
      manual: "Manuel CMR",
      search: "Rechercher",
      cmrnumber: "NUMÉRO CMR",
      selectdestiny: "Choisir une destination",
      selectcarrier: "Choisir le trasnporteur",
      changeuser: "Changement",
      canceluser: "Annuler",
      modaltitleuser: "Modifier le profil",
      userlocale: "LOCALE",
      userrole: "RÔLE",
      username: "NOM D'UTILISATEUR",
      decarange: "Plage de temps",
      decahidden: "temps caché",
      thereIsNoInfo: "Il n'y a aucune information",
      openMainMenu: "Ouvrir le menu principal",
      superAdmin: "Super administrateur",
      admin: "Administrateur",
      driver: "Conducteur",
      spanish: "Espagnol",
      english: "Anglais",
      french: "Français",
      portuguese: "portugais",
      italian: "italien",
      notAuthentificanted: "Non authentifié",
      name: "Nom",
      expedition: "Expédition",
      boxes: "Boîtes",
      weight: "Poids",
      volume: "Volume",
      selectStateRaq: "Sélectionnez STATUT raq",
      trailerInGoodCondition: "Remorque en bon état",
      packingList: "Liste de colisage",
      bonoTransportation: "Bon de transport",
      emptyPallets: "palettes vides",
      dangerousMaterials: "Matières dangereuses",
      confirm: "Confirmer",
      selectACenter: "Sélectionnez un centre",
      edit: "Modifier",
      save: "Sauvegarder",
      centerName: "Nom du centre",
      adress: "Adresse",
      city: "Ville",
      country: "Pays",
      countryCode: 'Code du pays',
      destinyName: "Nom de la destination",
      destinyNumber: "Numéro de destination",
      emailDestiny: "E-mail de destination",
      emailOrganizer: "E-mail de l'organisateur",
      emailAccountant: "E-mail du comptable",
      team: "Équipe",
      dock: "Quai",
      transitTime: "Temps de transit (jours)",
      loadOrder: "Type de chargement",
      selectLoadOrder: "Sélectionnez un type de chargement",
      kilometers: "Distance",
      isANationalDestiny: "C'est une destination nationale",
      selectADestiny: "Sélectionnez une destination",
      selectATruckType: "Sélectionnez un type de camion",
      share: "Partager",
      price: "Prix",
      selectADay: "Sélectionnez un jour",
      selectAPlanning: "Sélectionnez un horaire",
      selectAFuel: "Sélectionnez un carburant",
      download: "Déchargement",
      cancel: "Annuler",
      process: "Processus",
      registration: "Numero d'expedition",
      fuel: "Carburant",
      trailer: "Remorque",
      time: "Temps",
      dayLoad: "Jour de chargement",
      weekLoad: "Semaine de chargement",
      hourLoad: "Chargement (Prévisionnel)",
      selectHourLoad: "Sélectionnez une heure de charge",
      hourDownload: "Heure de déchargement",
      comment: "Commentaire",
      seals: "Plombs",
      return: "Retour",
      returns: "Retours",
      create: "Créer",
      selectACarrier: "Sélectionnez un transporteur",
      billingDestination: "Destination de facturation",
      date: "Date",
      arrivalHour: "Heure d'arrivée",
      createPlanning: "Créer un planning",
      downloadPlanning: "Télécharger le planning",
      address: "Adresse",
      fullAddress: "Rue, Numéro, Ville",
      number: "Nº magasin",
      type: "Type",
      loadingTiming: "Arrivée du transporteur",
      loaderTiming: "Productivité du chargeur",
      endLoadingHour: "Fin de chargement (Réel)",
      selectCenter: "Sélectionnez le type de centre",
      centerType: "Type de centre",
      typeWarehouse: "Type d'entrepôt",
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
      section: "Section",
      store: "Magasin",
      load: "Chargement",
      editPlanning: "Modifier le calendrier",
      selectDay: "Sélectionnez un jour",
      loading: "Chargement...",
      bye: "À bientôt!",
      noCmrsToClose: "Pas de CMR à fermer",
      welcome: "Bienvenue",
      warning: "Avertissement",
      downloadDate: "Date de déchargement",
      billing: "Facturation",
      weekDownload: "Semaine de téléchargement",
      dayDownload: "Jour de déchargement",
      copy: "Copie",
      charger: "Chargeur",
      manualbox: "Bon manuel",
      areyousure: "Es-tu sûr?",
      deletedText: "Une fois supprimé, vous ne pourrez plus le récupérer",
      deletedsuccess: "Supprimé avec succès",
      shipped: "Envoyé",
      returned: "Colis manuel",
      writeHere: "Écrivez ici",
      hello: "Bonjour",
      initialdate: "A PARTIR DE",
      finaldate: "Jusqu'à",
      protocolLink: "Protocole de sécurité",
      languagePreference: "Sélectionnez la langue",
      timeZone: "Fuseau horaire",
      selectTimeZone: "Sélectionnez le fuseau horaire",
      language: "Langue",
      timez: "fuseau horaire",
      planningsCreated: "Planifications créées avec succès",
      hours: "Heures",
      reception: "Réceptions",
      receptionHours: "HEURE DE RÉCEPTION PRÉVU",
      receptionHoursReal: "HEURE DE RÉCEPTION RÉELLE",
      origin: "Origine",
      arrivals: "Arrivées",
      selectOrderType: "Sélectionnez le type de stock",
      filter: "Filtre",
      deletefilter: "Supprimer le filtre",
      as400Alert: "Si vous utilisez AS400 pour rechercher une expédition, veillez à ne pas utiliser le préfixe d'expédition.",
      weightAlert: "Veuillez noter que le poids ne dépasse pas ce qui est autorisé par la loi.",
      expeAS400: "Numéro d'expédition (pas de préfixe)",
      returnCollaborator: "Revenir collaborateur",
      returnDate: "Date de retour",
      shipmentDateOnReturnMenu: "Date d'expédition",
      shipmenCollaboratorOnReturnMenu: "Expéditeur",
      alertDeleting: "Vous supprimerez tous les plannings qui ne sont pas encore associés à une CMR. Vous ne pourrez pas annuler cette action.",
      selectSector: "Sélectionnez le secteur",
      selectDestinyType: "Sélectionnez le type de destination",
      destinyType: "Type de destination",
      chargerName: "Nom du chargeur",
      searchingShipmentAS400: "Recherche de données d'expédition dans AS400",
      searchingRaqAS400: "Vérification de l'état du RAQ sur l'AS400",
      searchingShipmentNumberAS400: "Je recherche un numéro d'expédition dans AS400",
      waitPlease: "S'il vous plaît, attendez",
      errorRaq: "Statut RAQ introuvable pour cette destination",
      errorRaqDesc: "Vérifiez si le storeNumber est correctement défini dans la configuration DCMR. Si le problème persiste, complétez le RAQ manuellement.",
      raqQuestion: "Je n'ai trouvé aucun résultat dans RAQ pour cette destination. Le RAQ est-il correct ?",
      atention: "Attention",
      thenErrorRaq: "Donc une erreur a dû se produire",
      thenErrorRaqDesc: "Veuillez vérifier si le storeNumber est correctement défini.",
      selectPackaging: "Sélectionnez un emballage",
      shipments: "Expédition",
      returnsDoneFromYourCenter: "Retours effectués dans votre centre",
      returnsDoneFrom: "Retours effectués dans ",
      shipmentsDoneFromYourCenter: "Nombre de livraisons depuis votre centre",
      shipmentsDoneFrom: "Nombre de livraisons depuis",
      yourCenter: "votre centre",
      shipmentsDoneByCenter: "Envois effectués par centre",
      shipmentsDoneByDestiny: "Envois effectués par destination",
      shipmentsDoneByCarrier: "Expéditions effectuées par transporteur",
      shipmentsDoneByTruckType: "Expéditions effectuées par type de camion",
      returnsDoneByCenter: "Retours effectués par centre",
      returnsDoneByDestiny: "Retours effectués par destination",
      returnsDoneByCarrier: "Retours effectués par transporteur",
      arrivalDate: "Date d'arrivée",
      departureDate: "Date de départ",
      driverPuntuality: "Ponctualité du chauffeur",
      quantity: "Quantité",
      fill: "Remplissage",
      dataValidated: "Données valables à partir de la semaine",
      recepInStore: "Réception en magasin (Réel)",
      successInStore: "CMR enregistré avec succès",
      year: "Année",
      cmrInStoreMenu: "Accueil CMR Magasin",
      driverPuntualityInStore: "Ponctualité du chauffeur en magasin",
      myCenter: "Mon entrepôt",
      allDestinies: "Toutes les destinations",
      allCarriers: "Tous les transporteurs",
      shipmentClosedCorrectly: "Envoi correctement clôturé",
      shipmentNotFound:"Le numéro d'expédition de l'AS400 n'a pas pu être récupéré.",
      shipmentWarning: "Veuillez vous assurer que le numéro d'expédition récupéré est correct",
      shipmentNotFoundAdvice: "Veuillez vérifier que le numéro de destination est correct dans le menu de Configuration.",
      delete:"Supprimer",
      deletePlannings: "Supprimer plannings",
      cif:"Numéro de SIREN",
      nif:"NOM ET JOUR DE NAISSANCE",
      searchingPackingList: "Je recherche packing list dans AS400...",
      selectEnvironment: "Sélectionnez environment",
      uploadingFileInBucket: "Téléchargement du fichier vers le cloud...",
      tryAgainContactAdmin: "Veuillez réessayer. Si le problème persiste, contactez l'administrateur",
      as400Name:"Nom dans AS400",
      customizedName: "Nom personnalisé",
      shipmentDoesNotExistInData: "Cet envoi n'existe pas dans le DCMR",
      selectEnvWarning: "Veuillez sélectionner un environnement d'abord",
      receptionNumber: "Nº Réception",
      missingPackagingType: "Type d'emballage manquant",
      missingPackagingTypeText: "Souhaitez-vous que je crée pour vous le type d'emballage suivant et que je réessaie de générer le CMR ? --> ",
      packagingCreated: "Emballage créé avec succès",
      yes:"Oui",
      no:"Non",
      errorCreatingPackaging: "Désolé, une erreur s'est produite lors de la création de l'emballage. Veuillez le créer manuellement dans Configuration -> Emballage ou réessayer plus tard."


      },
 
    
}

export const parseLocale = (locale) => {
      let lowerLocale
      if(locale != null){
      lowerLocale = locale.toLowerCase() 
      }else{
      lowerLocale = "ES"
      }
      return lowerLocale
}

